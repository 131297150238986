import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import CategoriesList from 'components/CategoriesList';
import { SliderVariant } from 'components/Slider';
import CardListing from 'containers/CardListing';
import Carousel from 'containers/Carousel';

import { useFilters } from 'hooks/useFilters';

const RecipesListingPage: FC<RecipesListingPageTypes.RecipesListingProps> = ({
  data: {
    recipeListing: {
      urls,
      seo,
      tags,
      banner,
      dropdownPlaceholder,
      filtersTitle,
      productCarousel,
      introCopyText,
    },
    allRecipeDetail: { recipesList },
    allProductDetail: { productsList },
  },
}) => {
  const { title, keywords, description } = seo;

  const { PRODUCTS } = SliderVariant;

  const {
    selectedCategories,
    sortedItems: sortedArticles,
    allTags,
    handleSelectedCategories,
  } = useFilters(recipesList, 'all');

  const destructArticles = sortedArticles.map((el) => ({ ...el, card: el.card[0] }));

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} />
      <CategoriesList
        {...{
          filtersTitle,
          dropdownPlaceholder,
          allTags,
          tags,
          selectedCategories,
          handleSelectedCategories,
          introCopyText,
        }}
      />
      <CardListing cards={destructArticles} />
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={PRODUCTS}
          carousel={productCarousel}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $productsUrls: [String], $path: [String]) {
    recipeListing(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      dropdownPlaceholder
      filtersTitle
      introCopyText
      tags {
        ...TagFragment
      }
      banner {
        ...BannerFragment
      }
      productCarousel {
        ...CarouselFragment
      }
    }

    allRecipeDetail {
      recipesList: nodes {
        ...RecipeCardFragment
      }
    }

    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default RecipesListingPage;
